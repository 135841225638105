<template>
    <div class="HomeView">
        <v-tabs v-model="tab" bg-color="primary">
            <v-tab value="one">기본</v-tab>
            <v-tab value="nine">시범사업</v-tab>
            <v-tab value="seven">가입코드</v-tab>
            <v-tab value="eight" v-if="false">워크샵 가입자</v-tab>
            <v-tab value="two">전체</v-tab>
            <v-tab value="three">환자/보건의료인</v-tab>
            <v-tab value="four">약국별</v-tab>
            <v-tab value="five">중재군/대조군</v-tab>
            <v-tab value="six">개인별</v-tab>
            <v-tab value="ten" v-if="$root.user.f_user > 0">장기미접속자</v-tab>
            <v-tab value="eleven" v-if="$root.user.f_user > 0">건강정보 미등록</v-tab>
        </v-tabs>
        <v-window v-model="tab">
            <v-window-item value="one">
                <div class="Basic">
                    <div class="row">
                        <div class="col">
                            <CountBox type="user_online" color="red" :reloadable="true" />
                            <CountBox type="user_join_today" color="blue" :reloadable="true" />
                            <CountBox type="user_total" color="blue" :reloadable="true" />
                        </div>
                        <ChartBox type="platform_user_access" :reloadable="false" />
                    </div>
                    <div class="row">
                        <ChartBox type="platform_user_join" />
                        <div class="col">
                            <ChartBox type="status_user_count" :reloadable="true" />
                        </div>
                    </div>
                </div>
            </v-window-item>
            <v-window-item value="two">
                <HomeTable :headers="usage.headers_1" :items="usage.list" :items-per-page="usage.paging.itemsPerPage"
                    :p_type="p_type" @change_p_type="change_p_type" @dn_usage="dn_usage" :is_loading="is_loading">
                </HomeTable>
            </v-window-item>
            <v-window-item value="three">
                <HomeTable :headers="usage.headers_2" :items="usage.list" :items-per-page="usage.paging.itemsPerPage"
                    :p_type="p_type" @change_p_type="change_p_type" @dn_usage="dn_usage" :is_loading="is_loading"
                    :sort_by="[{ key: 'p_index', order: 'asc' }, { key: 'group_name', order: 'asc' }]">
                </HomeTable>
            </v-window-item>
            <v-window-item value="four">
                <HomeTable :headers="usage.headers_2_2" :items="usage.list" :items-per-page="usage.paging.itemsPerPage"
                    :p_type="p_type" @change_p_type="change_p_type" @dn_usage="dn_usage" :is_loading="is_loading"
                    :sort_by="[{ key: 'p_index', order: 'asc' }, { key: 'group_name', order: 'asc' }, { key: 'cate', order: 'asc' }]">
                </HomeTable>
            </v-window-item>
            <v-window-item value="five">
                <HomeTable :headers="usage.headers_2_2" :items="usage.list" :items-per-page="usage.paging.itemsPerPage"
                    :p_type="p_type" @change_p_type="change_p_type" @dn_usage="dn_usage" :is_loading="is_loading"
                    :sort_by="[{ key: 'p_index', order: 'asc' }, { key: 'group_name', order: 'asc' }, { key: 'cate', order: 'asc' }]">
                </HomeTable>
            </v-window-item>
            <v-window-item value="six">
                <HomeTable :headers="personal_header" :items="usage.list" :items-per-page="usage.paging.itemsPerPage"
                    :p_type="p_type" @change_p_type="change_p_type" @dn_usage="dn_usage" :is_loading="is_loading"
                    :sort_by="[{ key: 'p_index', order: 'asc' }, { key: 'date1', order: 'asc' }, { key: 'name', order: 'asc' }]">
                </HomeTable>
            </v-window-item>
            <v-window-item value="seven">
                <div class="search_opt">
                    <BasicSelect v-model="join_code.cate" :items="join_code.cates" />
                    <InputText v-model="join_code.name" placeholder="이름" />
                    <v-btn @click="load">새로고침</v-btn>
                </div>
                <BasicDataTable v-model:page="join_code.page" :headers="join_code.headers" :items="filteredList7"
                    :items-per-page="join_code.paging.itemsPerPage" :loading="is_loading"
                    :sort-by="[{ key: '이름', order: 'asc' }]" multi-sort>

                    <template v-slot:no-data>
                        <div>정보가 없습니다.</div>
                    </template>

                </BasicDataTable>
                <ul class="guide">
                    <li>※ 최대가입자수가 0이면 가입자수 제한이 없습니다.</li>
                    <li>현재 : 로그인 가능한 유저</li>
                    <li>철회 : 동의철회로 로그인불가</li>
                    <li>유효 : 동의철회와 관계없이 통계 유효</li>
                </ul>

            </v-window-item>
            <v-window-item value="eight">
                <div class="search_opt">
                    <InputText v-model="join_user.name" placeholder="이름" />
                    <InputText v-model="join_user.mobile" placeholder="휴대폰뒷자리" />
                    <v-btn @click="load">새로고침</v-btn>
                </div>

                <BasicDataTable v-model:page="join_user.page" :headers="join_user.headers" :items="filteredList8"
                    :items-per-page="join_user.paging.itemsPerPage" :loading="is_loading"
                    :sort-by="[{ key: '이름', order: 'asc' }]" multi-sort>

                    <template v-slot:no-data>
                        <div>정보가 없습니다.</div>
                    </template>

                </BasicDataTable>
            </v-window-item>
            <v-window-item value="nine">
                <div class="Basic">
                    <div class="row">
                        <ChartBox type="platform_user_access_g1" :reloadable="false" />
                        <div class="col">
                            <CountBox type="user_total_g1" color="blue" :reloadable="true" />
                            <CountBox type="user_total_g2" color="red" :reloadable="true" />
                        </div>
                    </div>
                    <div class="row">
                        <ChartBox type="platform_user_access_g2" />
                    </div>
                </div>
            </v-window-item>

            <v-window-item value="ten">
                <BasicDataTable v-model:page="long_term.page" :headers="long_term.headers" :items="long_term.items"
                    :items-per-page="long_term.paging.itemsPerPage" :loading="is_loading"
                    :sort-by="[{ key: 'name', order: 'asc' }]" multi-sort>

                    <template v-slot:no-data>
                        <div>정보가 없습니다.</div>
                    </template>

                    <template v-slot:[`item.create_time`]="{ item }">
                        {{ filters.formatDateTime(item.create_time) }}
                    </template>

                    <template v-slot:[`item.last_access`]="{ item }">
                        {{ filters.formatDateTime(item.last_access) }}
                    </template>

                    <template v-slot:[`item.diff`]="{ item }">
                        {{ item.diff }}일
                    </template>

                </BasicDataTable>
            </v-window-item>

            <v-window-item value="eleven">
                <BasicDataTable v-model:page="no_bio.page" :headers="no_bio.headers" :items="no_bio.items"
                    :items-per-page="no_bio.paging.itemsPerPage" :loading="is_loading"
                    :sort-by="[{ key: 'name', order: 'asc' }]" multi-sort>

                    <template v-slot:no-data>
                        <div>정보가 없습니다.</div>
                    </template>

                    <template v-slot:[`item.create_time`]="{ item }">
                        {{ filters.formatDateTime(item.create_time) }}
                    </template>

                    <template v-slot:[`item.last_access`]="{ item }">
                        {{ filters.formatDateTime(item.last_access) }}
                    </template>

                    <template v-slot:[`item.diff`]="{ item }">
                        {{ item.diff }}일
                    </template>

                    <template v-slot:[`item.none`]="{ item }">
                        <template v-if="item.gender == 'none'">성명/생년월일</template>
                        <span class="space"></span>
                        <template v-if="item.disease == 'none'">질병</template>
                    </template>

                </BasicDataTable>
            </v-window-item>
        </v-window>

    </div>
</template>
<script>
import BasicDataTable from '@/components/BasicDataTable'
import BasicSelect from '@/components/BasicSelect'
import ChartBox from '@/components/report/ChartBox.vue'
import CountBox from '@/components/report/CountBox.vue'
import HomeTable from '@/components/HomeTable.vue'
import InputText from '@/components/InputText'

import ng from '@/assets/js/ng'

import filters from '@/assets/js/filters'

import moment from 'moment'

export default {
    name: 'HomeView',
    components: {
        BasicDataTable,
        BasicSelect,
        ChartBox,
        CountBox,
        HomeTable,
        InputText
    },
    props: {},
    computed: {
        personal_header() {
            if (this.p_type == 'D') return this.usage.headers_3;
            else return this.usage.headers_4;
        },
        filteredList7() {
            return this.join_code.items.filter((i) => {
                return (
                    (this.join_code.name == "" || 0 == i.이름.indexOf(this.join_code.name))
                );
            });
        },
        filteredList8() {
            return this.join_user.items.filter((i) => {
                return (
                    (this.join_user.name == "" || 0 == i.이름.indexOf(this.join_user.name)) &&
                    (this.join_user.mobile == "" || 0 == i.휴대폰뒷자리.indexOf(this.join_user.mobile))
                );
            });
        },
    },
    data: () => ({
        filters: filters,
        is_loading: false,
        tab: 'one',
        usage: {
            headers0: [
                {
                    title: "총 접속횟수",
                    align: "center",
                    key: "ACCESS_COUNT",
                    width: "100px",
                },
                {
                    title: "총 접속시간(초)",
                    align: "center",
                    key: "ACCESS_TIME",
                    width: "100px",
                },
                {
                    title: "안전서항 조회",
                    align: "center",
                    key: "CV_SAFETY_LETTER",
                    width: "100px",
                },
                {
                    title: "회수/폐기 조회",
                    align: "center",
                    key: "CV_DISCARDS",
                    width: "100px",
                },
                {
                    title: "처방이력",
                    align: "center",
                    key: "CR_MY_DRUG_PRESCRIPTION",
                    width: "100px",
                },
                {
                    title: "복용의약품",
                    align: "center",
                    key: "CR_MY_DRUG_TAKES",
                    width: "100px",
                },
                {
                    title: "복용알림",
                    align: "center",
                    key: "CR_MY_DOSE",
                    width: "100px",
                },
                {
                    title: "검색기록",
                    align: "center",
                    key: "CR_MY_DRUG_HISTORY",
                    width: "100px",
                },
                {
                    title: "내 건강정보",
                    align: "center",
                    key: "CR_MY_HEALTH",
                    width: "100px",
                },
                {
                    title: "관심의약품",
                    align: "center",
                    key: "CR_MY_DRUG_BOOKMARK",
                    width: "100px",
                },
                {
                    title: "고급검색(초)",
                    align: "center",
                    key: "TP_SEARCH_EXPERT",
                    width: "100px",
                },
                {
                    title: "고급검색(횟수)",
                    align: "center",
                    key: "CP_SEARCH_EXPERT",
                    width: "100px",
                },
                {
                    title: "낱알검색(초)",
                    align: "center",
                    key: "TP_SEARCH_SHAPE",
                    width: "100px",
                },
                {
                    title: "낱알검색(횟수)",
                    align: "center",
                    key: "CP_SEARCH_SHAPE",
                    width: "100px",
                },
                {
                    title: "QR검색(초)",
                    align: "center",
                    key: "TP_SEARCH_QRCODE",
                    width: "100px",
                },
                {
                    title: "QR검색(횟수)",
                    align: "center",
                    key: "CP_SEARCH_QRCODE",
                    width: "100px",
                },
                {
                    title: "음성검색(초)",
                    align: "center",
                    key: "TP_SEARCH_VOICE",
                    width: "100px",
                },
                {
                    title: "음성검색(횟수)",
                    align: "center",
                    key: "CP_SEARCH_VOICE",
                    width: "100px",
                },

                {
                    title: "의약품 상세정보(초)",
                    align: "center",
                    key: "TP_DRUG",
                    width: "100px",
                },
                {
                    title: "의약품 상세정보(횟수)",
                    align: "center",
                    key: "CP_DRUG",
                    width: "100px",
                },
                {
                    title: "환자약력 조회",
                    align: "center",
                    key: "CR_EXPERT_SHARE",
                    width: "100px",
                },
                {
                    title: "내 약력 전송",
                    align: "center",
                    key: "CP_SHARE_MY_BIO",
                    width: "100px",
                },
                {
                    title: "안전성서한 알림",
                    align: "center",
                    key: "CR_NOTICE_SAFETY_LETTER",
                    width: "100px",
                },
                {
                    title: "수어영상",
                    align: "center",
                    key: "CR_EDU_SIGN",
                    width: "100px",
                },
                {
                    title: "교육자료",
                    align: "center",
                    key: "CR_EDU_DATA",
                    width: "100px",
                },
                {
                    title: "건강기능식품 주의",
                    align: "center",
                    key: "CR_FOOD_WARNING",
                    width: "100px",
                },
                {
                    title: "학술정보",
                    align: "center",
                    key: "CR_EDU_LINK",
                    width: "100px",
                },
            ],
            headers_1: [],
            headers_2: [],
            headers_3: [],
            headers_4: [],
            list: [],
            paging: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                total: 0,
            },
        },
        p_type: 'D',
        group: {
            'U': '환자',
            'E': '보건의료인',
            'I': '중재군',
            'C': '대조군'
        },
        join_code: {
            name: "",
            page: 1,
            header: [],
            items: [],
            paging: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                total: 0,
            },
            cate: "환자",
            cates: [
                "약사",
                "환자",
                "연구진",
                "기타"
            ]
        },
        join_user: {
            name: "",
            mobile: '',
            page: 1,
            header: [],
            items: [],
            paging: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                total: 0,
            },
        },
        long_term: {
            name: "",
            mobile: '',
            page: 1,
            headers: [
                {
                    title: "이름",
                    align: "center",
                    key: "name",
                    width: "100px",
                },
                {
                    title: "전화번호",
                    align: "center",
                    key: "mobile",
                    width: "100px",
                },
                {
                    title: "가입약국",
                    align: "center",
                    key: "organization_name",
                    width: "100px",
                },
                {
                    title: "가입일",
                    align: "center",
                    key: "create_time",
                    width: "100px",
                },
                {
                    title: "최근 접속일시",
                    align: "center",
                    key: "last_access",
                    width: "100px",
                },
                {
                    title: "미접속일",
                    align: "center",
                    key: "diff",
                    width: "100px",
                },
            ],
            items: [],
            paging: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                total: 0,
            },
        },
        no_bio: {
            name: "",
            mobile: '',
            page: 1,
            headers: [
                {
                    title: "이름",
                    align: "center",
                    key: "name",
                    width: "100px",
                },
                {
                    title: "전화번호",
                    align: "center",
                    key: "mobile",
                    width: "100px",
                },
                {
                    title: "가입약국",
                    align: "center",
                    key: "organization_name",
                    width: "100px",
                },
                {
                    title: "가입일",
                    align: "center",
                    key: "create_time",
                    width: "100px",
                },
                {
                    title: "가입후 경과",
                    align: "center",
                    key: "diff",
                    width: "100px",
                },
                {
                    title: "누락된 정보",
                    align: "center",
                    key: "none",
                    width: "100px",
                },
            ],
            items: [],
            paging: {
                page: 1,
                pageCount: 0,
                itemsPerPage: 10,
                total: 0,
            },
        }
    }),
    mounted() {
        this.usage.headers_1 = [
            {
                title: "회차",
                align: "center",
                key: "p_index",
                width: "100px",
            },
            ...this.usage.headers0
        ]
        this.usage.headers_2 = [
            {
                title: "회차",
                align: "center",
                key: "p_index",
                width: "100px",
            },
            {
                title: "식별",
                align: "center",
                key: "group_name",
                width: "100px",
            },
            ...this.usage.headers0
        ];

        this.usage.headers_2_2 = [
            {
                title: "회차",
                align: "center",
                key: "p_index",
                width: "100px",
            },
            {
                title: "식별",
                align: "center",
                key: "group_name",
                width: "100px",
            },
            {
                title: "분류",
                align: "center",
                key: "cate",
                width: "100px",
            },
            ...this.usage.headers0
        ];

        this.usage.headers_3 = [
            {
                title: "회차",
                align: "center",
                key: "p_index",
                width: "100px",
            },
            {
                title: "날짜",
                align: "center",
                key: "date1",
                width: "100px",
            },
            {
                title: "이름",
                align: "center",
                key: "name",
                width: "100px",
            },
            {
                title: "나이",
                align: "center",
                key: "age",
                width: "100px",
            },
            /*
            {
                title: "식별",
                align: "center",
                key: "g_id",
                width: "100px",
            },*/
            ...this.usage.headers0
        ];

        this.usage.headers_4 = [
            {
                title: "회차",
                align: "center",
                key: "p_index",
                width: "100px",
            },
            {
                title: "날짜1",
                align: "center",
                key: "date1",
                width: "100px",
            },
            {
                title: "날짜2",
                align: "center",
                key: "date2",
                width: "100px",
            },
            {
                title: "이름",
                align: "center",
                key: "name",
                width: "100px",
            },
            {
                title: "나이",
                align: "center",
                key: "age",
                width: "100px",
            },
            /*
            {
                title: "식별",
                align: "center",
                key: "g_id",
                width: "100px",
            },*/
            ...this.usage.headers0
        ];

        this.load();
    },
    methods: {
        change_p_type(val) {
            this.p_type = val;
        },
        get_filename() {
            var p_type = {
                "D": "일일",
                "W": "주간",
                "M": "월간",
                "Q": "분기별",
                "T": "전체"
            };

            if (this.tab == 'two') return p_type[this.p_type] + "_전체_사용량_통계"
            else if (this.tab == 'three') return p_type[this.p_type] + "_환자-보건의료인_사용량_통계"
            else if (this.tab == 'four') return p_type[this.p_type] + "_약국별_통계"
            else if (this.tab == 'five') return p_type[this.p_type] + "_중재-대조군_사용량_통계"
            else if (this.tab == 'six') return p_type[this.p_type] + "_개인별_사용량_통계"
        },
        dn_usage() {
            var url = '';
            var file_name = this.get_filename() + '_' + moment().format('YYYYMMDD') + '.xlsx';

            if (this.tab == 'two') { url = '/api/statics/platform/usage/group/A'; }
            else if (this.tab == 'three') { url = '/api/statics/platform/usage/group/U' }
            else if (this.tab == 'four') { url = '/api/statics/platform/usage/group/P' }
            else if (this.tab == 'five') { url = '/api/statics/platform/usage/group/G' }
            else if (this.tab == 'six') { url = '/api/statics/platform/usage/user' }

            if (url != '') {
                url += `?p_type=${this.p_type}&target=excel`
                ng.download(url, file_name)
            }
        },
        load() {
            this.usage.list = [];
            if (this.tab == 'two') {
                this.is_loading = true;
                this.$api.statics.platform_usage_group_A({}, { p_type: this.p_type }).then(response => {
                    this.set_response(response);
                })
            } else if (this.tab == 'three') {
                this.is_loading = true;
                this.$api.statics.platform_usage_group_U({}, { p_type: this.p_type }).then(response => {
                    this.set_response(response);
                })
            } else if (this.tab == 'four') {
                this.is_loading = true;
                this.$api.statics.platform_usage_group_P({}, { p_type: this.p_type }).then(response => {
                    this.set_response(response);
                })
            } else if (this.tab == 'five') {
                this.is_loading = true;
                this.$api.statics.platform_usage_group_G({}, { p_type: this.p_type }).then(response => {
                    this.set_response(response);
                })
            } else if (this.tab == 'six') {
                this.is_loading = true;
                this.$api.statics.platform_usage_user({}, { p_type: this.p_type }).then(response => {
                    this.set_response(response);
                })
            } else if (this.tab == 'seven') {
                this.is_loading = true;
                this.$api.manage.temp.가입자코드_현황({}, { cate: this.join_code.cate }).then(response => {
                    this.join_code.items = response.data.data;
                    this.is_loading = false;
                })
            } else if (this.tab == 'eight') {
                this.is_loading = true;
                this.join_user.items = [];
                this.$api.manage.temp.워크샵_가입리스트({}).then(response => {
                    response.data.data.forEach(item => {
                        if (item.mobile_suffix != null)
                            this.join_user.items.push({ 이름: item.name, "휴대폰뒷자리": item.mobile_suffix });
                    });
                    this.is_loading = false;
                })
            } else if (this.tab == 'ten') {
                this.is_loading = true;
                this.$api.status.user_list_not_connected_long_term({}, {}).then(response => {
                    this.long_term.items = response.data.data;
                    this.is_loading = false;
                })
            } else if (this.tab == 'eleven') {
                this.is_loading = true;
                this.$api.status.user_list_not_register_bio({}, {}).then(response => {
                    this.no_bio.items = response.data.data;
                    this.is_loading = false;
                })
            }
        },
        set_response(response) {
            this.usage.list = response.data.data;
            this.usage.list.forEach(item => {
                this.set_item(item);
            });
            this.is_loading = false;
        },
        set_item(item) {
            if (item.g_id != undefined) {
                if (this.group[item.g_id] != undefined) {
                    item.group_name = this.group[item.g_id];
                } else {
                    item.group_name = item.g_id;
                }
            }
        }
    },
    watch: {
        tab() {
            this.load()
        },
        p_type() {
            this.load()
        },
        'join_code.cate'() {
            this.load();
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/_variables.scss";

.HomeView {
    &:deep(.v-tab.v-tab.v-btn) {
        min-width: 50px;
        padding: 0px 10px;
    }
}

.Basic {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
    padding-top: 10px;

    .row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 10px;
    }

    .col {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 10px;

        &>div {
            flex-grow: 1;
        }
    }
}

.btn_p_type {
    padding: 10px;

    button {
        border: 1px solid #475569;
        padding: 5px 20px;
        font-size: 16px;
        border-radius: 20px;
        margin-right: 10px
    }
}

.btn_p_type.btn_d {
    button.D {
        border: 1px solid #475569;
        background-color: #475569;
        color: white;
    }
}

.btn_p_type.btn_w {
    button.W {
        border: 1px solid #475569;
        background-color: #475569;
        color: white;
    }
}

.btn_p_type.btn_m {
    button.M {
        border: 1px solid #475569;
        background-color: #475569;
        color: white;
    }
}

.search_opt {
    display: flex;
    padding: 5px 0px;
}

.search_opt>div {
    max-width: 200px;
    margin-right: 5px;
}

.search_opt:deep(.v-select) {
    margin-top: 0px;
}

span.space {
    display: inline-block;
    width: 5px;
}
</style>